export default [
  //学生管理界面
  {
    path: "student",
    component: () => import("@/components/student/Manage.vue"),
    meta: { isAuth: true, role: "student" },
    children: [
      {
        path: "center",
        component: () => import("@/components/student/center/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "startexam",
        component: () => import("@/components/student/startExam/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "record",
        component: () => import("@/components/student/record/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "chat",
        component: () => import("@/components/student/chat/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "usermanager",
        component: () => import("@/components/student/userManager/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "help",
        component: () => import("@/components/student/help/index.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "ranking",
        component: () => import("@/components/student/community/ranking.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "circle",
        component: () => import("@/components/student/community/circle.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "ask",
        component: () => import("@/components/student/community/ask.vue"),
        meta: { isAuth: true, role: "student" },
      },
      {
        path: "wrong",
        component: () => import("@/components/exam/wrong.vue"),
        meta: { isAuth: true, role: "student" },
      },
    ],
  },
];
