export default [
  //超级管理界面
  {
    path: "super",
    component: () => import("@/components/super/Manage.vue"),
    meta: { isAuth: true, role: "super" },
    children: [
      {
        path: "teacherManage",
        component: () =>
          import("@/components/super/userManage/teacherManage.vue"),
        meta: { isAuth: true, role: "super" },
      },
      {
        path: "studentManage",
        component: () =>
          import("@/components/super/userManage/studentManage.vue"),
        meta: { isAuth: true, role: "super" },
      },
      {
        path: "center",
        component: () => import("@/components/super/center/index.vue"),
        meta: { isAuth: true, role: "super" },
      },
      {
        path: "settings",
        component: () => import("@/components/super/settings/index.vue"),
        meta: { isAuth: true, role: "super" },
      },
      {
        path: "help",
        component: () => import("@/components/super/help/index.vue"),
        meta: { isAuth: true, role: "super" },
      },
      {
        path: "class",
        component: () => import("@/components/super/className.vue"),
        meta: { isAuth: true, role: "super" },
      },
    ],
  },
];
