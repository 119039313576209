import Vue from "vue";
import { Message } from "element-ui";
import axios from "axios";
import Router from "vue-router";
import student from "./module/student";
import teacher from "./module/teacher";
import admin from "./module/super";

Vue.use(Router);

const router = new Router({
  routes: [
    { path: "/", redirect: "/login" },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/components/Login.vue"),
    },
    {
      path: "/register",
      component: () => import("@/components/Register.vue"),
    },
    {
      path: "/forgetPassword",
      component: () => import("@/components/forgetPassword.vue"),
    },
    {
      path: "/examing/:id",
      component: () => import("@/components/Examing.vue"),
      meta: { isAuth: true, role: "student" },
    },
    {
      path: "/analysis/:id",
      component: () => import("@/components/exam/analysis.vue"),
      meta: { isAuth: true, role: "student" },
    },
    {
      path: "/manage",
      component: () => import("@/components/Manage"),
      children: [...student, ...teacher, ...admin],
    },
    { path: "*", component: () => import("@/components/404") },
    {
      path: "/error",
      name: "error",
      component: () => import("@/components/error"),
    },
  ],
});

//全局前置路由守卫---在每一次路由切换之前,初始化的时候被调用
router.beforeEach((to, from, next) => {
  // console.log(to);
  if (to.meta.isAuth) {
    //判断是否要鉴权
    //必须要token
    if (localStorage.getItem("token")) {
      axios
        .post("/api/global/loginStatus", {
          token: localStorage.getItem("token"),
        })
        .then((res) => {
          if (res.data.status == 1) {
            // console.log("token", res.data.data, to.meta.role);
            //token合法
            if (res.data.data.identity === to.meta.role) {
              //要去往的页面rules是否与当前role相等
              next(); //角色匹配已登录，正常进入to.meta.roles[i]的页面
            } else {
              Message({ type: "error", message: "无权限" });
              next("/error");
            }
          } else {
            Message({ type: "error", message: "身份验证失败" });
            next("/login");
          }
        });
    } else {
      Message({ type: "error", message: "请登录" });
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
