<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>



<style>
#app {
  width: 100%;
  height: 100%;
  color: black;
  /* background-color: #408080; */
}
a{
  text-decoration: none;
}
</style>
