import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        userdata:JSON.parse(localStorage.getItem('Userdata')),
        config:JSON.parse(localStorage.getItem('Config'))|| {
            "theme": "skyblue",
            "ad": "广告测试",
            "friend_chain": [
                {
                    "href": "http://www.baidu.com",
                    "label": "百度"
                },
                {
                    "label": "优酷",
                    "href": "http://www.youku.com"
                }
            ],
            "desc": "联系我们：2324094523@qq.com",
            "announcement": [
                "根据学校信息化建设，现推出在线考试系统，希望各位老师带领学生积极加入",
                "2021第二学年期末考试时间为12.27-1.5,望各位老师通知到位",
                ""
            ]
        },
    },
    mutations:{
        setUserdata:(state,userdata)=>{
            state.userdata = userdata
        },
        setConfig: (state, config) => {
            state.config = config;
        },
    }
})

export default store