export default [
  //教师管理界面
  {
    path: "teacher",
    component: () => import("@/components/teacher/Manage.vue"),
    meta: { isAuth: true, role: "teacher" },
    children: [
      {
        path: "questions",
        component: () =>
          import("@/components/teacher/questions_bank/Index.vue"),
        children: [
          {
            path: "edit",
            component: () =>
              import("@/components/teacher/questions_bank/edit/Index.vue"),
            meta: { isAuth: true, role: "teacher" },
          },
          {
            path: "list",
            component: () =>
              import("@/components/teacher/questions_bank/list/Index.vue"),
            meta: { isAuth: true, role: "teacher" },
          },
        ],
      },
      {
        path: "paper",
        component: () => import("@/components/teacher/exam_paper/Index.vue"),
        children: [
          {
            path: "index",
            component: () =>
              import("@/components/teacher/exam_paper/index/Index.vue"),
            meta: { isAuth: true, role: "teacher" },
          },
          {
            path: "edit",
            component: () =>
              import("@/components/teacher/exam_paper/edit/index.vue"),
            meta: { isAuth: true, role: "teacher" },
          },
        ],
      },
      {
        path: "chat",
        component: () => import("@/components/teacher/chat/index.vue"),
        meta: { isAuth: true, role: "teacher" },
      },
      {
        path: "usermanager",
        component: () => import("@/components/teacher/userManager/index.vue"),
        meta: { isAuth: true, role: "teacher" },
      },
      {
        path: "center",
        component: () => import("@/components/teacher/center/index.vue"),
        meta: { isAuth: true, role: "teacher" },
      },
      {
        path: "record",
        component: () => import("@/components/teacher/record/index.vue"),
        meta: { isAuth: true, role: "teacher" },
      },
      {
        path: "help",
        component: () => import("@/components/teacher/help/index.vue"),
        meta: { isAuth: true, role: "teacher" },
      },
    ],
  },
];
