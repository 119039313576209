
import Vue from 'vue'
import Vuex from 'vuex'
import store from './store/store'
import App from './App'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import  VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueClipboard from 'vue-clipboard2'
import * as echarts from 'echarts'
//5.0以上版本不支持 import直接引入

import Host from './config/host'

Vue.use(VueQuillEditor)

Vue.use(ElementUI)
Vue.use(Vuex)

Vue.use(VueClipboard)

Vue.prototype.$echarts = echarts 
Vue.prototype.host = Host
//axios.defaults.baseURL = "http://localhost:3000";
// axios.defaults.baseURL = "http://139.199.156.234:3000";
axios.defaults.baseURL = "https://exam.xyxiang.cn";
Vue.prototype.$http=axios

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
